import "./popup-module-editor.scss";

import React from "react";
import { useController, useForm } from "react-hook-form";

import { Form } from "../app-editor/form";
import Button from "../app-editor/components/button/button";
import DefaultTextBox from "../../../components/text-box/text-box";
import DefaultCheckbox from "../../../components/check-box/check-box";
import DefaultDialog from "../../../components/custom-dialog/default-dialog";
import DefaultSelectBox from "../../../components/select-box/select-box";

function Input({ name, label, placeholder, errorMessage, disabled, required }) {
  const { field, fieldState } = useController({ name, rules: { required, validate: (value) => !!value.trim() } });

  const errorText = () => {
    if (fieldState.error.type === 'required' || fieldState.error.type === "validate") {
      return <span className="errorText">This field is required</span>
    };
    return "";
  }

  return (
    <DefaultTextBox
      onTbvalueChange={field.onChange}
      value={field.value}
      name={field.name}
      caption={label}
      placeholder={placeholder}
      stylingMode="outlined"
      displayStar={required}
      isResetStyle
      errorMessage={errorMessage}
      disabled={disabled}
      renderBottom={fieldState.invalid ? errorText() : undefined}
    />
  );
}

function CheckboxField({ name, label, disabled }) {
  const { field } = useController({ name });

  return (
    <div style={{ width: "100%" }}>
      <DefaultCheckbox
        onValueChanged={(value) => field.onChange(value)}
        value={field.value}
        name={field.name}
        label={label}
        labelWidth={100}
        flexReverse
        isResetStyle
        align="center"
        disabled={disabled}
      />
    </div>
  );
}

function SelectInput({ label, name, required, disabled }) {
  const { field } = useController({ name, required });

  return (
    <DefaultSelectBox
      onValueChanged={field.onChange}
      value={field.value}
      items={[
        {
          id: "NONE",
          label: "-",
        },
        {
          id: "AIG",
          label: "Associated Item Group",
        },
        {
          id: "ACG",
          label: "Associated Customer Group",
        },
        {
          id: "AVG",
          label: "Associated Vendor Group",
        },
        {
          id: "CMP",
          label: "Company",
        },
      ]}
      displayExpr="label"
      valueExpr="id"
      caption={label}
      isResetStyle
      displayStar={required}
      disabled={disabled}
    />
  );
}

const defaultValues = {
  AppId: 0,
  TableId: 0,
  TableName: "",
  MenuType: 2,
  AssociateGroup: "NONE",
  Name: "",
  Caption: "",
  MenuTypeText: "",
}


export default function EditMenuPopup(props) {
  const { onHiding, onConfirm, data } = props;

  const methods = useForm({
    defaultValues: {
      AppId: data.appId?.replaceAll("UA-", ""),
      TableName: data.name,
      Caption: data.caption,
      MenuType: data.menuType,
      MenuTypeText: data.type,
      AssociateGroup: data.associateGroup,
      Active_YN: data.activeYn,
    },
  });

  const { reset } = methods;

  const onSubmit = async (values) => {
    try {
      onConfirm?.(values);
    } catch (error) {
      console.error(error);
    }
  };

  const _onHiding = () => {
    props.close?.();
    reset(defaultValues);
    onHiding(false);
  };

  return (
    <DefaultDialog title="Edit Menu" onClose={_onHiding} width={400} height={571}>
      <Form style={{ height: "100%" }} methods={methods} onSubmit={onSubmit}>
        <div style={{ flex: 1, position: "relative", height: '100%' }}>
          <div style={{ position: 'absolute', inset: 0, overflow: 'hidden' }}>
            <div style={{ height: '100%', overflow: 'auto', position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 20, padding: 24 }}>
                <Input name="TableName" label="Menu Code" disabled />
                <Input name="Caption" label="Menu Name" placeholder="Enter Menu Name" required />
                <Input name="MenuTypeText" label="Type" placeholder="Type" disabled />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ marginLeft: 6, fontSize: 14, marginBottom: 8, fontWeight: 'bold', color: '#474751' }}>Status</span>
                  <CheckboxField name="Active_YN" label="Active" />
                </div>
                <SelectInput label="Associated Group" name="AssociateGroup" disabled />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 8,
            backgroundColor: "#F7F7F7",
            padding: 24,
          }}
        >
          <Button type="submit">Confirm</Button>
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={_onHiding}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </DefaultDialog>
  );
}
